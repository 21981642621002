.App {
  font-family: Arial, Helvetica, sans-serif;
  padding: 20px;
  text-align: center;
  width: 900px;
  margin: 10px auto;
  border: 2px #ccc solid;
  border-radius: 10px;
}

.list-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.online-processors,
.offline-processors {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  margin: 10px;
  border-radius: 10px;
  width: 400px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.online-processors {
  background-color: #90ee90; /* Very light green */
}

.offline-processors {
  background-color: #ff7f7f; /* Very light red */
}

.list {
  width: 200px;
  margin: 0 auto;
}
